<template>
  <div>
    <Dialog
      :modal="comparisonDialogModal"
      @close-clicked="closeComparisonDialogModal()"
      @save-clicked="setComparisonDialogModal()"
    >
      <template v-slot:header> Comparison period(s) </template>
      <template v-slot:content>
        Please select the desired period(s)
        <v-form ref="form">
          <v-text-field
            type="number"
            label="Enter a number"
            background-color="inputBackground"
            class="text-no-wrap rounded-md custom mt-2"
            filled
            v-model="comparisonDialogModel"
            min="1"
            max="36"
            :rules="rules"
            hide-details
          ></v-text-field>
        </v-form>
      </template>
    </Dialog>
    <v-menu
      ref="startComparisonMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="comparisonModel"
          label="Compare With"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          dense
          hide-details
        ></v-text-field>
      </template>
      <v-card>
        <span
          class="fs-13 fw-400 px-2 secondary--text"
          style="word-break: break-all"
          >Select Period</span
        >
        <v-list>
          <v-radio-group v-model="customFiltersPayload.type">
            <v-list-item
              v-for="(item, index) in customFiltersTypes"
              :key="index"
            >
              <v-list-item-title>
                <v-radio
                  :label="item.label"
                  :color="
                    item.value === customFiltersPayload.type
                      ? 'primary'
                      : 'secondary'
                  "
                  :value="item.value"
                >
                  <template v-slot:label>
                    <span
                      class="fs-13 fw-400 px-2 secondary--text"
                      style="word-break: break-all"
                      >{{ item.label }}</span
                    >
                  </template>
                </v-radio>
              </v-list-item-title>
            </v-list-item>
          </v-radio-group>
          <v-divider></v-divider>

          <v-list-item-group class="mt-1" v-model="customFiltersPayload.value">
            <v-list-item
              v-for="(item, index) in [0, 1, 2, 3, 4, 5, 6]"
              :key="index"
            >
              <v-list-item-title v-if="index === 0">None </v-list-item-title>
              <v-list-item-title
                @click="comparisonDialogModal = true"
                v-else-if="index == 6"
                >Enter a different number
              </v-list-item-title>
              <v-list-item-title v-else
                >{{ item }}
                {{
                  index === 1
                    ? customFiltersPayload.type.slice(0, -1)
                    : customFiltersPayload.type
                }}</v-list-item-title
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- <div v-if="customFiltersPayload.type !== 'days'">
          <v-divider></v-divider>
          <v-checkbox
            class="mx-2"
            v-model="customFiltersPayload.financialEndCheck"
          >
            <template v-slot:label>
              <span
                class="fs-13 fw-400 px-2 secondary--text"
                style="word-break: break-all"
                >Compare to End of Financial Year</span
              >
            </template></v-checkbox
          >
        </div> -->
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Dialog from "../../common/dialog-box/index.vue";
export default {
  data() {
    return {
      customFiltersTypes: [
        {
          value: "days",
          label: "Day",
        },
        {
          value: "months",
          label: "Month",
        },
        {
          value: "quarters",
          label: "Quarter",
        },
        {
          value: "years",
          label: "Year",
        },
      ],
      startComparisonMenu: false,
      comparisonDialogModal: false,
      comparisonDialogModel: "",
      rules: [
        (val) => !!val || "Field is required",
        (val) =>
          (val && Number(val) >= 0 && Number(val) <= 35) ||
          "Value should be positive and less than 36",
      ],
    };
  },
  props: {
    customFiltersPayload: Object,
  },
  components: {
    Dialog,
  },
  computed: {
    comparisonModel() {
      if (!this.customFiltersPayload.value) {
        this.$emit("update-comparison-number", 0);
        return "None";
      }
      if (this.customFiltersPayload.value === 0) return "None";
      else
        return `Compare with ${this.customFiltersPayload.value} ${
          this.customFiltersPayload.value === 1
            ? this.customFiltersPayload.type.slice(0, -1)
            : this.customFiltersPayload.type
        }`;
    },
  },
  mounted() {},
  methods: {
    closeComparisonDialogModal() {
      this.comparisonDialogModal = false;
      this.$refs.form.reset();
    },
    setComparisonDialogModal() {
      try {
        if (!this.$refs.form.validate()) {
          return this.$showNotificationBar(
            "error",
            "Kindly select the valid input"
          );
        }
        this.$emit("update-comparison-number", this.comparisonDialogModel);
        this.closeComparisonDialogModal();
      } catch (error) {
        this.$showNotificationBar("error", error);
        this.$refs.form.reset();
      }
    },
  },
};
</script>
