<template>
  <div style="width: 90% !important" class="mx-auto">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <!-- {{ subTotals }} -->
    <v-row class="d-flex justify-end my-2 mx-13">
      <v-col cols="6"></v-col>
      <v-col cols="6" class="d-flex justify-end align-center">
        <CustomFilterMenu
          @update-comparison-number="onUpdateComparisonNumber"
          :customFiltersPayload="customFiltersPayload"
        />
        <custom-date-menu
          @selectedDate="dateFromMenu($event)"
          class="ml-4"
          style="width: 260px !important"
        />
      </v-col>
    </v-row>
    <!-- </v-col>
      <v-col cols="2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker in menu"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1"> -->
    <v-row class="d-flex justify-end mt-0 mx-12">
      <v-col cols="2" class="text-end">
        <Button
          @button-clicked="fetchBalanceSheet"
          :loader="loader"
          class="mr-1"
        >
          <template v-slot:buttonHeading>
            <p class="ma-0 pa-0 primary--text" style="letter-spacing: -0.3px">
              Update
            </p>
          </template>
        </Button>
      </v-col>
    </v-row>
    <Loader v-if="loader" />
    <v-row
      v-else
      class="text-center ma-0 mt-2"
      :style="
        cols.length >= 5
          ? 'padding: 5px 0 5px 20px !important'
          : 'padding: 0 !important'
      "
    >
      <v-col cols="12" class="ma-0 pa-0">
        <v-data-table
          hide-default-header
          :items-per-page="10"
          :height="550"
          disable-pagination
          hide-default-footer
          dense
          fixed-header
          class="elevation-0"
          style="width: 90% !important; margin: auto !important"
        >
          <template slot="header">
            <thead>
              <tr>
                <th
                  class="lightPurple text-end"
                  v-for="(header, headerKey) in cols"
                  :key="headerKey + 'A'"
                >
                  <span class="white--text"> {{ header.text }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template #body="">
            <tbody
              v-for="(mainCategory, mainKey) in balanceSheet"
              :key="mainKey + 'B'"
            >
              <tr class="reportSubHeading">
                <td
                  v-for="(item, index) in cols"
                  :key="index + 'C'"
                  class="text-start fw-600"
                >
                  {{ index === 0 ? mainKey : "" }}
                </td>
              </tr>

              <template v-for="(subCategory, subKey) in mainCategory">
                <tr class="reportTotal" style="width: 100%" :key="subKey + 'D'">
                  <td
                    class="text-start"
                    v-for="(item, index) in cols"
                    :key="index + 'E'"
                  >
                    <span class="pl-4 fw-500">{{
                      index === 0 ? subKey : ""
                    }}</span>
                  </td>
                </tr>

                <tr v-for="(item, key) in subCategory" :key="key + 'F'">
                  <td
                    class="text-start white"
                    style="
                      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                    "
                    :class="`${key === 'Total' ? 'reportTotal' : ''}`"
                  >
                    <span class="pl-8 text-no-wrap">{{ key }}</span>
                  </td>
                  <td
                    class="text-end"
                    :class="`${key === 'Total' ? 'reportTotal' : ''}`"
                    style="
                      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                    "
                    v-for="(item3, index3) in newBalanceSheet"
                    :key="index3 + 'G'"
                  >
                    <span
                      v-if="
                        item3.balances[mainKey] &&
                        item3.balances[mainKey][subKey] &&
                        item3.balances[mainKey][subKey][key] &&
                        item3.balances[mainKey][subKey][key]?.balanceDebit &&
                        item3.balances[mainKey][subKey][key].balanceDebit !=
                          0 &&
                        key != 'Total'
                      "
                    >
                      {{
                        item3.balances[mainKey][subKey][key]["balanceDebit"]
                          | handleNegativeValue
                      }}</span
                    >
                    <span
                      v-else-if="
                        item3.balances[mainKey] &&
                        item3.balances[mainKey][subKey] &&
                        item3.balances[mainKey][subKey][key] &&
                        item3.balances[mainKey][subKey][key]?.balanceCredit &&
                        item3.balances[mainKey][subKey][key].balanceCredit !=
                          0 &&
                        key != 'Total'
                      "
                      >{{
                        item3.balances[mainKey][subKey][key]["balanceCredit"]
                          | handleNegativeValue
                      }}</span
                    >
                    <span
                      v-else-if="
                        item3.balances[mainKey] &&
                        item3.balances[mainKey][subKey] &&
                        item3.balances[mainKey][subKey][key] &&
                        item3.balances[mainKey][subKey] &&
                        key === 'Total'
                      "
                    >
                      {{
                        item3.balances[mainKey][subKey]["Total"]
                          | handleNegativeValue
                      }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td
                    v-if="varianceCheck"
                    class="text-end white"
                    style="
                      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                    "
                  >
                    <span
                      v-if="balanceSheet[mainKey][subKey][key]['variance']"
                      :class="
                        balanceSheet[mainKey][subKey][key]['variance'] <= 0
                          ? 'red--text'
                          : 'green--text'
                      "
                      >{{ balanceSheet[mainKey][subKey][key]["variance"] }} %
                    </span>
                    <span v-else> - </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>

<script>
import CustomFilterMenu from "../common/CustomFilterMenu.vue";
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api.js";
import Button from "../../common/button/index.vue";
import Loader from "../../common/xero-form/Loader.vue";
import moment from "moment";
import CustomDateMenu from "../common/CustomDateMenu.vue";
// import NoSoftwareConnected from "../../common/connect-software/NoSoftwareConnected.vue";
export default {
  data() {
    return {
      varianceCheck: false,
      balanceSheet: [],
      dateRange: [],
      dates: [],
      menu: false,
      startDate: null,
      endDate: null,
      startDateMenu: false,
      customFiltersPayload: {
        type: "days",
        value: 0,
        financialEndCheck: false,
      },
      loader: true,
      newBalanceSheet: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
    };
  },
  components: {
    CustomFilterMenu,
    Button,
    Loader,
    CustomDateMenu,
    // NoSoftwareConnected,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
    cols() {
      let cols = [];
      cols.push({
        text: ``,
        value: `Header without value`,
        width: 200,
        sortable: false,
      });
      for (let i = 0; i < this.newBalanceSheet.length; i++) {
        if (i == 0)
          this.newBalanceSheet[i].endDate = moment(
            this.newBalanceSheet[i].endDate
          ).format("YYYY-MM-DD");
        cols.push({
          text: `${this.newBalanceSheet[i]?.endDate}`,
          value: `${this.newBalanceSheet[i]?.endDate}`,
          width: 200,
          sortable: false,
        });
      }
      // Columns for variance
      if (this.newBalanceSheet.length === 2) {
        this.varianceCheck = true;
        cols.push({
          text: "Var",
          value: "variance",
          width: 100,
          sortable: false,
        });
      }
      return cols;
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  mounted() {
    this.dates = [];
    // this.fetchBalanceSheet();
  },
  methods: {
    dateFromMenu(val) {
      this.date = val;
      this.fetchBalanceSheet();
    },
    async fetchBalanceSheet() {
      this.loader = true;

      let url = `report/balance-sheet/${this.currentWorkspace.workspaceId}`;
      this.startDate = null;
      this.endDate = this.date;
      // this.startDate = this.dates[0] ? this.dates[0] : null;
      // this.endDate = this.dates[1] ? this.dates[1] : null;

      if (this.startDate || this.endDate) {
        url += `?startDate=${this.startDate}&endDate=${this.endDate}`;
      }

      await ApiService.GetRequest(url, this.customFiltersPayload)
        .then((res) => {
          this.loader = false;
          console.log(res.data.balances, "DATA");
          this.balanceSheet = res.data.balances[0].balances;
          this.newBalanceSheet = res.data.balances;
        })
        .catch((err) => {
          console.log(err.response, "Err");
          this.balanceSheet = [];
          this.newBalanceSheet = [];
          this.loader = false;
        });
    },
    onUpdateComparisonNumber(number) {
      this.customFiltersPayload.value = number;
    },
  },
};
</script>

<style scoped>
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 4 !important;
  min-width: 300px;
}

table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
}

.border {
  border: 2px solid var(--v-primary-base);
  padding: 10px;
  border-radius: 25px;
}
</style>
