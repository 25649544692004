var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto",staticStyle:{"width":"90% !important"}},[_c('v-row',{staticClass:"d-flex justify-end my-2 mx-13"},[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[_c('CustomFilterMenu',{attrs:{"customFiltersPayload":_vm.customFiltersPayload},on:{"update-comparison-number":_vm.onUpdateComparisonNumber}}),_c('custom-date-menu',{staticClass:"ml-4",staticStyle:{"width":"260px !important"},on:{"selectedDate":function($event){return _vm.dateFromMenu($event)}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-end mt-0 mx-12"},[_c('v-col',{staticClass:"text-end",attrs:{"cols":"2"}},[_c('Button',{staticClass:"mr-1",attrs:{"loader":_vm.loader},on:{"button-clicked":_vm.fetchBalanceSheet},scopedSlots:_vm._u([{key:"buttonHeading",fn:function(){return [_c('p',{staticClass:"ma-0 pa-0 primary--text",staticStyle:{"letter-spacing":"-0.3px"}},[_vm._v(" Update ")])]},proxy:true}])})],1)],1),(_vm.loader)?_c('Loader'):_c('v-row',{staticClass:"text-center ma-0 mt-2",style:(_vm.cols.length >= 5
        ? 'padding: 5px 0 5px 20px !important'
        : 'padding: 0 !important')},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"90% !important","margin":"auto !important"},attrs:{"hide-default-header":"","items-per-page":10,"height":550,"disable-pagination":"","hide-default-footer":"","dense":"","fixed-header":""},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.balanceSheet),function(mainCategory,mainKey){return _c('tbody',{key:mainKey + 'B'},[_c('tr',{staticClass:"reportSubHeading"},_vm._l((_vm.cols),function(item,index){return _c('td',{key:index + 'C',staticClass:"text-start fw-600"},[_vm._v(" "+_vm._s(index === 0 ? mainKey : "")+" ")])}),0),_vm._l((mainCategory),function(subCategory,subKey){return [_c('tr',{key:subKey + 'D',staticClass:"reportTotal",staticStyle:{"width":"100%"}},_vm._l((_vm.cols),function(item,index){return _c('td',{key:index + 'E',staticClass:"text-start"},[_c('span',{staticClass:"pl-4 fw-500"},[_vm._v(_vm._s(index === 0 ? subKey : ""))])])}),0),_vm._l((subCategory),function(item,key){return _c('tr',{key:key + 'F'},[_c('td',{staticClass:"text-start white",class:`${key === 'Total' ? 'reportTotal' : ''}`,staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12) !important"}},[_c('span',{staticClass:"pl-8 text-no-wrap"},[_vm._v(_vm._s(key))])]),_vm._l((_vm.newBalanceSheet),function(item3,index3){return _c('td',{key:index3 + 'G',staticClass:"text-end",class:`${key === 'Total' ? 'reportTotal' : ''}`,staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12) !important"}},[(
                      item3.balances[mainKey] &&
                      item3.balances[mainKey][subKey] &&
                      item3.balances[mainKey][subKey][key] &&
                      item3.balances[mainKey][subKey][key]?.balanceDebit &&
                      item3.balances[mainKey][subKey][key].balanceDebit !=
                        0 &&
                      key != 'Total'
                    )?_c('span',[_vm._v(" "+_vm._s(_vm._f("handleNegativeValue")(item3.balances[mainKey][subKey][key]["balanceDebit"])))]):(
                      item3.balances[mainKey] &&
                      item3.balances[mainKey][subKey] &&
                      item3.balances[mainKey][subKey][key] &&
                      item3.balances[mainKey][subKey][key]?.balanceCredit &&
                      item3.balances[mainKey][subKey][key].balanceCredit !=
                        0 &&
                      key != 'Total'
                    )?_c('span',[_vm._v(_vm._s(_vm._f("handleNegativeValue")(item3.balances[mainKey][subKey][key]["balanceCredit"])))]):(
                      item3.balances[mainKey] &&
                      item3.balances[mainKey][subKey] &&
                      item3.balances[mainKey][subKey][key] &&
                      item3.balances[mainKey][subKey] &&
                      key === 'Total'
                    )?_c('span',[_vm._v(" "+_vm._s(_vm._f("handleNegativeValue")(item3.balances[mainKey][subKey]["Total"]))+" ")]):_c('span',[_vm._v(" - ")])])}),(_vm.varianceCheck)?_c('td',{staticClass:"text-end white",staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12) !important"}},[(_vm.balanceSheet[mainKey][subKey][key]['variance'])?_c('span',{class:_vm.balanceSheet[mainKey][subKey][key]['variance'] <= 0
                        ? 'red--text'
                        : 'green--text'},[_vm._v(_vm._s(_vm.balanceSheet[mainKey][subKey][key]["variance"])+" % ")]):_c('span',[_vm._v(" - ")])]):_vm._e()],2)})]})],2)})},proxy:true}])},[_c('template',{slot:"header"},[_c('thead',[_c('tr',_vm._l((_vm.cols),function(header,headerKey){return _c('th',{key:headerKey + 'A',staticClass:"lightPurple text-end"},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(header.text))])])}),0)])])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }