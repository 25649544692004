<template>
  <div>
    <!-- <v-form> -->
    <!-- <span class="f-inter fs-12">Date: {{ dateValue }}</span> -->
    <v-select
      dense
      outlined
      :items="items"
      item-text="value"
      item-value="key"
      v-model="selectedValue"
      @change="setSelectedValue()"
      hide-details
    />
    <!-- </v-form> -->
    <v-dialog
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      width="290px"
    >
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="revertSelectedOption()">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="saveDate()"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import dateFilter from "../../../js/DateFilters.js";
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      selectedValue: "",
      items: [
        { key: "today", value: "Today" },
        { key: "endOfThisMonth", value: "End of this month" },
        { key: "previousMonth", value: "End of last month" },
        { key: "previousQuarter", value: "End of last quarter" },
        { key: "endOfLastFinancialYear", value: "End of last financial year" },
        { key: "customDate", value: "Custom Date" },
      ],
      dateValue: "",
    };
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  mounted() {
    this.selectedValue = "today";
    this.setSelectedValue();
  },

  methods: {
    saveDate() {
      console.log(this.date, "Date");
      this.$emit("selectedDate", this.date);
      this.menu = false;
    },
    revertSelectedOption() {
      this.menu = false;
      this.selectedValue = "today";
      this.setSelectedValue();
    },
    async setSelectedValue() {
      if (this.selectedValue != "customDate") {
        const selectedDate = await dateFilter.setDateFilter(this.selectedValue);
        let endDate;
        if (
          this.selectedValue == "previousQuarter" &&
          this.selectedValue != "customDate"
        ) {
          endDate = new Date(selectedDate.endDate + "Z").toISOString();
        } else if (
          this.selectedValue == "endOfLastFinancialYear" &&
          this.selectedValue != "customDate"
        ) {
          endDate = selectedDate.endDate;
        } else {
          endDate = selectedDate.endDate.toISOString().substr(0, 10);
        }
        this.$emit("selectedDate", endDate);
        this.dateValue = endDate;
      } else if (this.selectedValue == "customDate") {
        this.menu = true;
      }
    },
  },
};
</script>

<style></style>
