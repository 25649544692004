import moment from "moment/moment";
import store from "../store/index.js";
const dateFilter = {
  setDateFilter(value) {
    let currentDate = new Date();
    const daysInWeek = 7;
    let dateRangeModal = false;
    let lastWeekMonday, lastWeekSunday, start, end;
    switch (value) {
      case "today":
        start = new Date();
        start.setUTCHours(0, 0, 0, 0);
        end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "yesterday":
        start = new Date(currentDate);
        start.setDate(currentDate.getDate() - 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(currentDate);
        end.setDate(currentDate.getDate() - 1);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "thisWeek":
        let dayOfWeek = currentDate.getDay();
        let daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        let mondayDate = new Date(currentDate);
        mondayDate.setDate(currentDate.getDate() - daysUntilMonday);
        start = new Date(mondayDate);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date();
        break;
      case "previousWeek":
        lastWeekMonday = new Date();
        lastWeekMonday.setDate(
          currentDate.getDate() - daysInWeek - currentDate.getDay() + 1
        );
        start = new Date(lastWeekMonday);
        lastWeekSunday = new Date(lastWeekMonday);
        lastWeekSunday.setDate(lastWeekMonday.getDate() + daysInWeek - 1);
        end = new Date(lastWeekSunday);
        start.setUTCHours(0, 0, 0, 0);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "lastTwoWeeks":
        lastWeekMonday = new Date();
        lastWeekMonday.setDate(
          currentDate.getDate() - daysInWeek - currentDate.getDay() + 1
        );
        const last2WeekMonday = new Date();
        last2WeekMonday.setDate(
          currentDate.getDate() - daysInWeek * 2 - currentDate.getDay() + 1
        );
        start = new Date(last2WeekMonday);
        const last2WeekSunday = new Date(last2WeekMonday);
        last2WeekSunday.setDate(lastWeekMonday.getDate() + daysInWeek - 1);
        end = new Date(last2WeekSunday);
        start.setUTCHours(0, 0, 0, 0);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "thisMonth":
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        start = new Date(startOfMonth);
        // start.setUTCHours(0, 0, 0, 0);
        end = new Date();
        // end.setUTCHours(23, 59, 59, 999);
        break;
      case "previousMonth":
        const startOfCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfPreviousMonth = new Date(startOfCurrentMonth);
        endOfPreviousMonth.setDate(endOfPreviousMonth.getDate() - 1);
        const startOfPreviousMonth = new Date(
          endOfPreviousMonth.getFullYear(),
          endOfPreviousMonth.getMonth(),
          1
        );
        start = new Date(startOfPreviousMonth);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(endOfPreviousMonth);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "endOfThisMonth":
        const startOfCurrMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfCurrMonth = moment().endOf("month");

        start = new Date(startOfCurrMonth);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(endOfCurrMonth);
        end.setUTCHours(23, 59, 59, 999);
        console.log(start, "END", end);
        break;
      case "thisQuarter":
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const startOfQuarter = new Date(
          currentYear,
          Math.floor(currentMonth / 3) * 3,
          1
        );
        const endOfQuarter = new Date(
          currentYear,
          Math.floor(currentMonth / 3) * 3 + 3,
          0
        );
        start = new Date(startOfQuarter);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(endOfQuarter);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "previousQuarter":
        const currentMonthForPrev = currentDate.getMonth();
        const currentYearForPrev = currentDate.getFullYear();
        const startOfPreviousQuarter = new Date(
          currentYearForPrev,
          Math.floor(currentMonthForPrev / 3) * 3 - 3,
          1
        );
        const endOfPreviousQuarter = new Date(
          currentYearForPrev,
          Math.floor(currentMonthForPrev / 3) * 3,
          0
        );
        start = new Date(startOfPreviousQuarter);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(endOfPreviousQuarter);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "thisYear":
        const currentYearForThisYear = currentDate.getFullYear();
        const startOfYear = new Date(currentYearForThisYear, 0, 1);
        const endOfYear = new Date(currentYearForThisYear, 11, 31);
        start = new Date(startOfYear);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date(endOfYear);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "previousYear":
        const previousYear = currentDate.getFullYear() - 1;
        const startOfPreviousYear = new Date(previousYear, 0, 1);
        const endOfPreviousYear = new Date(previousYear, 11, 31);
        start = new Date(startOfPreviousYear);
        start.setUTCHours(0, 0, 0, 0);
        start.setDate(start.getDate() + 1);
        end = new Date(endOfPreviousYear);
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "endOfLastFinancialYear":
        let organisation =
          store.getters["auth/getCurrentWorkspace"].organisationDetails;
        let financialYearEndDay = organisation.financialYearEndDay;
        let financialYearEndMonth = organisation.financialYearEndMonth;

        const year = currentDate.getFullYear();
        const lastYear = currentDate.getFullYear() - 1;

        let todayDate = currentDate.toISOString().substring(0, 10);
        let sameOrBefore = moment(end).isSameOrBefore(todayDate);

        if (sameOrBefore) {
          const previousYear = currentDate.getFullYear();
          const startOfPreviousYear = new Date(previousYear, 0, 1);
          start = new Date(startOfPreviousYear);
          start.setUTCHours(0, 0, 0, 0);
          start.setDate(start.getDate() + 1);
          start = start.toISOString().substring(0, 10);
          end = `${year}-${financialYearEndMonth}-${financialYearEndDay}`;
        } else {
          const previousYear = currentDate.getFullYear() - 1;
          const startOfPreviousYear = new Date(previousYear, 0, 1);
          start = new Date(startOfPreviousYear);
          start.setUTCHours(0, 0, 0, 0);
          start.setDate(start.getDate() + 1);
          start = start.toISOString().substring(0, 10);
          end = `${lastYear}-${financialYearEndMonth}-${financialYearEndDay}`;
        }
        break;
      case "monthToDate":
        const startMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        start = new Date(startMonth);
        end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        break;

      case "quarterToDate":
        const currMonth = currentDate.getMonth();
        const currYear = currentDate.getFullYear();
        const startQuarter = new Date(
          currYear,
          Math.floor(currMonth / 3) * 3,
          1
        );

        start = new Date(startQuarter);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "yearToDate":
        const currentYearStart = currentDate.getFullYear();
        const startYear = new Date(currentYearStart, 0, 1);
        start = new Date(startYear);
        start.setDate(start.getDate() + 1);
        start.setUTCHours(0, 0, 0, 0);
        end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        break;
      case "customDateRange":
        dateRangeModal = true;
        break;
      default:
        throw new Error("Invalid date range option");
    }
    return {
      startDate: start,
      endDate: end,
      dateRangeModal: dateRangeModal,
    };
  },

  currentYearDate() {
    let start, end;
    let currentDate = new Date();
    const currentYearForThisYear = currentDate.getFullYear();
    const startOfYear = new Date(currentYearForThisYear, 0, 1);

    start = new Date(startOfYear);
    start.setDate(start.getDate());
    end = new Date();

    return {
      start: start.toISOString().substring(0, 10),
      end: end.toISOString().substring(0, 10),
    };
  },

  currentMonth() {
    let currentDate = new Date();
    let start, end;
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    console.log(startOfMonth, "startOfMonth");
    start = new Date(startOfMonth);
    end = new Date();

    return {
      start: start,
      end: end,
    };
  },
};
export default dateFilter;
